import React from "react"
import { Link } from "gatsby"

import Layout from "../components/common/layout"
import SEO from "../components/common/seo"

const NotFoundPage = () => (
	<Layout pageTitle="404: Not found">
		<SEO title="404: Not found" />
		<div className="has-text-centered">
			<h1 className="title">NOT FOUND <i className="ri-emotion-sad-line" /></h1>
			<p>You just hit a route that doesn&#39;t exist...<br /><Link to="/" className="has-text-weight-bold">Go Home <span className="icon align-icon"><i className="ri-home-2-line" /></span></Link></p>
		</div>
	</Layout>
)

export default NotFoundPage
